<template>
  <section class="tables">
    <Loader :loading="showLoader" />
    <div class="page-header">
      <h3 class="page-title">Footer Management </h3>
      <nav aria-label="breadcrumb">
        <div class="float-right">
          <router-link class="btn btn btn-add btn-primary" :to="`/website-management/add-footer`"
            >Add New
          </router-link>
        </div>
      </nav>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="table-responsive mb-0">
            <table class="table table-centered table-nowrap">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Logo</th>
                  <th>Content</th>
                  <th>Active</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody v-if="footer">
                <tr v-for="(item, index) in footer" :key="item.id">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.logo }}</td>
                  <td>{{ item.content }}</td>
                  <td :style="{ color: item.active === 1 ? 'green' : 'red' }">{{ item.active === 1 ? 'Active' : 'Inactive' }}</td>
                  <td>
                    <router-link
                       class="mr-2 table-icon" v-b-tooltip.hover title="Edit"
                      :to="`/content-page/edit-content/${item.id}`"
                    >
                      <i class="mdi mdi-pencil "></i>
                    </router-link>
                    <a v-on:click="deleteFile(item.id)"  class="mr-2 table-icon" v-b-tooltip.hover title="Delete">
                      <i class="mdi mdi-delete"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import { setingsApi } from "../../../api";
import Loader from "../../../components/Loader";
export default {
  name: "footer",
  components: {
    Loader,
  },
  data() {
    return {
      showLoader: false,
      footer:""
    };
  },
  mounted() {
    this.getfooters();
  },
  methods: {
    async getfooters() {
      this.showLoader = true;
      const { data } = await setingsApi.getFooter();
      this.footer = data.data.data;
      console.log(this.footer);
      this.showLoader = false;
    },
    deleteFile(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.showLoader = true;
          const response = setingsApi.deleteFooter(id).then((response) => {
            this.showLoader = false;
            if (response.data.code == 200) {
              this.getfooters()
              if (result.value) {
                this.$swal(
                  "Deleted!",
                  "Your file has been deleted.",
                  "success"
                );
              }
            }
          });
        }
      });
    },
    
  },
};
</script>

<style scoped></style>


